import React from 'react';
import { gql } from '@apollo/client';
import { CreateButton, Datagrid, TextField, TopToolbar } from 'react-admin';
import IdInput from 'components/Filter/IdInput';
import List from 'components/List';
import IdField from 'components/IdField';
import { useFileUpload } from 'hooks/useFileUpload';
import config from 'config';
import { Button, CircularProgress } from '@mui/material';

const AgencyLocationsFilters = [<IdInput />];

const AgencyLocationsActions = () => {
  const { hiddenInput, openFilePrompt, uploading } = useFileUpload({
    url: `${config.rails_url}/v3/admin/agency_locations/import`,
    accept: 'text/csv',
  });

  return (
    <TopToolbar>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {hiddenInput}
        <Button
          disabled={uploading}
          onClick={openFilePrompt}
          variant="outlined"
          size="small"
          endIcon={uploading ? <CircularProgress size={20} /> : undefined}
        >
          Import&nbsp;Agency Locations&nbsp;CSV
        </Button>
        <CreateButton />
      </div>
    </TopToolbar>
  );
};

const AgencyLocationsList = () => (
  <List
    hasCreate
    exporter={false}
    filters={AgencyLocationsFilters}
    actions={<AgencyLocationsActions />}
  >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <IdField />
      <TextField source="agency_id" />
      <TextField source="location_id" />
    </Datagrid>
  </List>
);

AgencyLocationsList.query = gql`
  query GET_LIST($input: AgencyLocationQueryInput) {
    _agencyLocationsMeta(input: $input) {
      count
    }
    agencyLocations(input: $input) {
      id
      status
      agency_id
      agency {
        id
        name
        legal_name
        status
      }
      location_id
      location {
        id
        name
        market {
          display_name
        }
        state {
          name
        }
      }
      auto_publish_per_diem_to_professionals
      auto_publish_assignments_to_professionals
    }
  }
`;

export default AgencyLocationsList;
