import RichTextInput from 'components/RichTextInput';
import React from 'react';
import {
  ArrayInput,
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  required,
  SelectInput,
  SimpleFormIterator,
  Labeled,
  FormDataConsumer,
} from 'react-admin';
import { JsonInput } from 'react-admin-json-view';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import SlugInput from './SlugInput';
import AttributeSlugInput from './AttributeSlugInput';
import { useFormContext } from 'react-hook-form';

const SIGNED_DOCUMENT_HELPER_TEXT = 'Set a valid "template_id" value from Hello Sign';
const ENUMERABLE_MULTI_HELPER_TEXT = 'Set a valid array of values in format of { "values": [] }';

const QUALIFICATION_ATTRIBUTE_FIELD_TYPES = [
  { id: 'valid_date', name: 'Date Taken' },
  { id: 'date', name: 'Date' },
  { id: 'expiration_date', name: 'Expiration Date' },
  { id: 'pdf', name: 'PDF' },
  { id: 'image_front', name: 'Image Front' },
  { id: 'image_back', name: 'Image Back' },
  { id: 'state', name: 'State' },
  { id: 'boolean', name: 'Boolean' },
  { id: 'license_number', name: 'License Number' },
  { id: 'text', name: 'Text' },
  { id: 'signed_document', name: 'Signed Document' },
  { id: 'enumerable_multi', name: 'Enumerable Multi' },
];

const DEFAULT_DATA_VALUE_BY_TYPE = {
  signed_document: { template_id: undefined },
  enumerable_multi: { values: [] },
  text: { num_lines: 4 },
};

const validateTemplateId = (value: unknown) =>
  value && !value['template_id']?.length ? SIGNED_DOCUMENT_HELPER_TEXT : undefined;

const validateRequired = required();

const QualificationCreateFields = () => {
  const { setValue } = useFormContext();
  const handleTypeChange = (fieldSource) => (event) => {
    const type = event.target.value;
    const data = DEFAULT_DATA_VALUE_BY_TYPE[type] || undefined;
    setValue(`${fieldSource}field_type`, type);
    setValue(`${fieldSource}data`, data);
  };
  return (
    <>
      <TextInput label="Long Name" source="long_name" validate={[validateRequired]} />
      <TextInput label="Short Name" source="short_name" validate={[validateRequired]} />
      <SlugInput />
      <EnumSelectInput
        label="Qualification Type"
        source="qualification_type"
        validate={[validateRequired]}
      />
      <Labeled label="Instructions">
        <RichTextInput source="instructions" />
      </Labeled>
      <ArrayInput
        fullWidth
        label="Qualification Attributes"
        source="qualification_attributes_attributes"
      >
        <SimpleFormIterator fullWidth sx={{ maxWidth: 600 }}>
          <TextInput fullWidth multiline label="Name" source="name" validate={[validateRequired]} />
          <FormDataConsumer>
            {({ getSource, scopedFormData }) => (
              <AttributeSlugInput
                fullWidth
                source={getSource('slug')}
                attributeName={scopedFormData.name}
              />
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ getSource }) => (
              <SelectInput
                fullWidth
                label="Field Type"
                source="field_type"
                choices={QUALIFICATION_ATTRIBUTE_FIELD_TYPES}
                validate={[validateRequired]}
                onChange={handleTypeChange(getSource(''))}
              />
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ getSource, scopedFormData }) => {
              switch (scopedFormData.field_type) {
                case 'signed_document':
                  return (
                    <JsonInput
                      defaultValue={DEFAULT_DATA_VALUE_BY_TYPE[scopedFormData.field_type]}
                      helperText={SIGNED_DOCUMENT_HELPER_TEXT}
                      source={getSource('data')}
                      validate={[validateRequired, validateTemplateId]}
                    />
                  );
                case 'enumerable_multi':
                  return (
                    <JsonInput
                      defaultValue={DEFAULT_DATA_VALUE_BY_TYPE[scopedFormData.field_type]}
                      helperText={ENUMERABLE_MULTI_HELPER_TEXT}
                      source={getSource('data')}
                      validate={[validateRequired]}
                    />
                  );
                case 'text':
                  return (
                    <JsonInput
                      defaultValue={DEFAULT_DATA_VALUE_BY_TYPE[scopedFormData.field_type]}
                      source={getSource('data')}
                      validate={[validateRequired]}
                    />
                  );
                default:
                  return null;
              }
            }}
          </FormDataConsumer>
          <BooleanInput fullWidth label="Required" source="required" defaultValue={false} />
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
};

const QualificationCreate = () => (
  <Create redirect="show">
    <SimpleForm>
      <QualificationCreateFields />
    </SimpleForm>
  </Create>
);

export default QualificationCreate;
