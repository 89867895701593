import React from 'react';
import { gql } from '@apollo/client';
import {
  TopToolbar,
  EditButton,
  Show,
  TabbedShowLayout,
  Tab,
  TabbedShowLayoutTabs,
  useRecordContext,
  useGetOne,
  useGetRecordId,
} from 'react-admin';
import AccountsTab from './tabs/AccountsTab';
import RefundFormButton from 'resources/refunds/RefundForm/RefundFormButton';
import useCheckAccess from 'hooks/useCheckAccess';
import LocationsTab from './tabs/LocationsTab';
import BillingGroupsTab from 'components/tabs/BillingGroupsTab';
import InfoTab from './tabs/InfoTab';
import RequirementsTab from './tabs/RequirementsTab';
import { Button, CircularProgress } from '@mui/material';
import { useFileUpload } from 'hooks/useFileUpload';
import config from 'config';
import SaasFeesTab from './tabs/SaasFeesTab';
import ApprovalGroupTab from './tabs/ApprovalGroupTab';
import LocationGroupsTab from './tabs/LocationGroupsTab';
import MergeCompaniesButton from './merge_companies/MergeCompaniesButton';

const CompanyActions = () => {
  const record = useRecordContext();
  const couldBeRefunded = record?.balance_cents > 0;

  const { hiddenInput, openFilePrompt, uploading } = useFileUpload({
    url: `${config.rails_url}/v3/admin/companies/${record?.id}/professionals_import`,
    accept: 'text/csv',
  });

  return (
    <TopToolbar>
      <MergeCompaniesButton />
      {couldBeRefunded && <RefundFormButton billingGroupId={record.id as number} />}
      {record?.id && record?.saas_credentialing_enabled && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {hiddenInput}
          <Button
            disabled={uploading}
            onClick={openFilePrompt}
            variant="outlined"
            size="small"
            endIcon={uploading ? <CircularProgress size={20} /> : undefined}
          >
            Import&nbsp;Professionals&nbsp;CSV
          </Button>
        </div>
      )}
      <EditButton />
    </TopToolbar>
  );
};

const CompaniesShow = () => {
  const checkAccess = useCheckAccess();

  const id = useGetRecordId();
  const { data: record } = useGetOne('Company', { id });
  if (!record) {
    return null;
  }

  const isSaasCredentialingEnabled = !!record?.saas_credentialing_enabled;
  const canShowSaasFeesTab =
    isSaasCredentialingEnabled &&
    checkAccess('update', 'Company', [
      'saas_per_job_flat_fee_cents',
      'saas_per_seat_monthly_cents',
      'saas_base_monthly_cents',
    ]);

  return (
    <Show actions={<CompanyActions />}>
      <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" />}>
        <Tab label="info">
          <InfoTab />
        </Tab>

        <Tab label="Accounts" path="accounts">
          <AccountsTab />
        </Tab>

        {checkAccess('view', 'Location') && (
          <Tab label="Locations" path="locations">
            <LocationsTab />
          </Tab>
        )}

        <Tab label="Location Groups" path="location-groups">
          <LocationGroupsTab />
        </Tab>

        {checkAccess('view', 'BillingGroup') && (
          <Tab label="Billing Groups" path="billing-groups">
            <BillingGroupsTab target="company_id" />
          </Tab>
        )}

        {checkAccess('view', 'Requirement') && (
          <Tab label="Requirements" path="requirements">
            <RequirementsTab />
          </Tab>
        )}

        {checkAccess('view', 'ApprovalGroup') && (
          <Tab label="Approval Groups" path="approval-groups">
            <ApprovalGroupTab />
          </Tab>
        )}
        {canShowSaasFeesTab && (
          <Tab label="Saas Fees" path="saas-fees">
            <SaasFeesTab />
          </Tab>
        )}
      </TabbedShowLayout>
    </Show>
  );
};

CompaniesShow.query = gql`
  query GET_ONE($id: Int!) {
    company(id: $id) {
      accounts {
        id
      }
      account_manager_id
      agency_enabled
      billing_groups {
        id
      }
      detailed_notes
      disable_rate_editing
      disabled_at
      display_pro_ehr_information
      disable_rate_editing
      dispute_submission_window_days
      health_system {
        id
        label
      }
      hide_invoicing_during_onboarding
      id
      last_time_job_posted_at
      locations {
        id
      }
      msp {
        id
        label
      }
      name
      onboarding_complete
      other_referral_source
      phone_number
      referral
      referral_source {
        id
        name
        label
      }
      referral_source_id
      rfm
      saas_credentialing_enabled
      saas_scheduling_enabled
      sales_associate_id
      uid
      verified
      vms_type {
        id
        label
      }
      saas_per_job_flat_fee_cents
      saas_base_monthly_cents
      saas_per_seat_monthly_cents
    }
  }
`;

export default CompaniesShow;
