import React from 'react';
import { Show, TabbedShowLayout, Tab, TabbedShowLayoutTabs } from 'react-admin';
import { gql } from '@apollo/client';
import useCheckAccess from 'hooks/useCheckAccess';
import AssignmentShowActions from './ShowActions';
import AssignmentRequestsTab from './tabs/AssignmentRequestsTab';
import JobDetailsShowTab from './tabs/JobDetailsTab';
import JobsTab from './tabs/JobsTab';
import ChargeRateDetailsTab from './tabs/ChargeRateDetailsTab';
import PayoutRateDetailsTab from './tabs/PayoutRatesDetailsTab';
import TimecardsTab from './tabs/TimecardsTab';
import PayoutsTab from './tabs/PayoutsTab';
import ContractsTab from './tabs/ContractsTab';
import LocationsTab from './tabs/LocationsTab';
import InvoicesTab from 'components/tabs/InvoicesTab';

const AssignmentsShow = () => {
  const checkAccess = useCheckAccess();

  return (
    <Show actions={<AssignmentShowActions />} data-testid="assignments-show">
      <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" />}>
        <Tab label="Job details">
          <JobDetailsShowTab />
        </Tab>
        <Tab label="Bill Rate" path="bill-rate">
          <ChargeRateDetailsTab />
        </Tab>
        <Tab label="Pay Rate" path="pay-rate">
          <PayoutRateDetailsTab />
        </Tab>
        {checkAccess('view', 'AssignmentRequest') && (
          <Tab label="Applicants" path="requests">
            <AssignmentRequestsTab canManage={checkAccess('create', 'AssignmentRequest')} />
          </Tab>
        )}
        {checkAccess('view', 'Location') && (
          <Tab label="Locations" path="locations">
            <LocationsTab />
          </Tab>
        )}
        {checkAccess('view', 'Job') && (
          <Tab label="Jobs" path="jobs">
            <JobsTab />
          </Tab>
        )}
        {checkAccess('view', 'Invoice') && (
          <Tab label="Invoices" path="invoices">
            <InvoicesTab target="assignment_id" showFilters={false} />
          </Tab>
        )}
        {checkAccess('view', 'Job') && (
          <Tab label="Timecards" path="timecards">
            <TimecardsTab />
          </Tab>
        )}
        {checkAccess('view', 'Job') && (
          <Tab label="Payouts" path="payouts">
            <PayoutsTab />
          </Tab>
        )}
        {checkAccess('view', 'Job') && (
          <Tab label="Contracts" path="contracts">
            <ContractsTab />
          </Tab>
        )}
      </TabbedShowLayout>
    </Show>
  );
};

AssignmentsShow.query = gql`
  query GET_ONE($id: Int!) {
    assignment(id: $id) {
      require_physical_signature
      signed_addendum
      is_extension
      per_diem
      attendance_policy
      original_assignment_id
      id
      is_w2
      professional_can_create_job
      professional_id
      administrator_id
      administrator {
        id
        account {
          id
          name
        }
      }
      referrer_professional_id
      referrer_professional {
        id
        account {
          id
          name
        }
      }
      recruiter_id
      recruiter {
        id
        account {
          id
          name
        }
      }
      order_number
      vms_order_url
      vms_assignment_id
      self_schedule
      positions {
        id
        display_name
      }
      surgery_types {
        id
        display_name
      }
      specialties {
        id
        label
      }
      posting_field_contents
      starts_date
      flexible_starts_date
      ends_date
      starts_time
      ends_time
      onboarding_due_date
      schedule_type
      lunch_break
      jobs_detail
      booked_at
      status
      cancellation_reason
      canceled_by_professional
      on_hold
      paused
      number_of_shifts_per_week
      hours_per_shift
      estimated_unpaid_break_time_minutes
      submittal_max
      notes
      estimated_total_hours
      suspicious
      patient_population
      completed_early
      cancellation_fee_window
      cancellation_fee_charge_hours
      guaranteed_minimum_hours
      weekly_guaranteed_minimum_hours
      enforce_guaranteed_minimum_hours
      unit
      float_requirements
      call_requirements
      referral_reward_cents
      created_at
      updated_at
      needs_item_url
      scrubs_provided
      covid_care
      track_unpaid_breaks
      local_only
      clinical_contact {
        id
        first_name
        last_name
        phone_number
      }
      booked_method
      booked_source
      vms_contact_name
      vms_contact_email
      vms_contact_phone
      number_of_openings
      charge_per_week_cents
      pay_per_week_cents
      lodging_per_diem_cents
      mie_per_diem_cents
      taxable_amount_cents
      nontaxable_amount_cents
      schedule_known
      charge_base_hourly_rate_cents
      payout_base_hourly_rate_cents
      vms_fee_percent
      payout_overtime_multiplier
      charge_overtime_multiplier
      payout_double_overtime_multiplier
      charge_double_overtime_multiplier
      payout_max_weekly_regular_hours
      payout_max_daily_regular_hours
      payout_max_daily_ot_hours
      charge_max_weekly_regular_hours
      charge_max_daily_regular_hours
      charge_max_daily_ot_hours
      payout_base_hourly_rate_currency
      fee_percent
      on_call_fee_percent
      charge_base_hourly_rate_currency
      payout_on_call_hourly_rate_cents
      payout_on_call_hourly_rate_currency
      charge_on_call_hourly_rate_cents
      charge_on_call_hourly_rate_currency
      payout_callback_multiplier
      charge_callback_multiplier
      payout_taxable_hourly_rate_cents
      payout_taxable_hourly_rate_currency
      max_mie_per_diem_cents
      max_lodging_per_diem_cents
      min_taxable_rate_cents
      social_security_burden_multiplier
      medicare_burden_multiplier
      federal_unemployment_max_burden_cents
      federal_unemployment_percent_burden_multiplier
      state_unemployment_max_burden_cents
      state_unemployment_percent_burden_multiplier
      compliance_burden_cents
      other_burden_multiplier
      non_billable_orientation_burden_hours
      vms_fee_burden_multiplier
      adp_fee_burden_cents
      available_professional_count
      travel_reimbursement_burden_cents
      medical_burden_cents
      one_time_bonus_burden_cents
      assignment_bonus_tier2_travel_amount_cents
      assignment_bonus_tier2_travel_amount_tcv_cents
      assignment_bonus_tier1_travel_amount_cents
      assignment_bonus_tier1_travel_amount_tcv_cents
      gross_profit_cents
      gross_margin
      w2_contract
      offer
      billable_orientation_hours
      locations {
        id
        covid_requirement
        timezone_lookup
        market {
          id
          display_name
          state {
            id
            covid_requirement
          }
        }
        state {
          id
          name
          covid_requirement
        }
      }
      assignment_locations {
        primary_location
        location {
          id
          sales_associate {
            id
            account {
              name
            }
          }
        }
      }
      primary_location {
        id
        timezone_lookup
        covid_requirement
        market {
          id
          state {
            id
            covid_requirement
          }
        }
        company {
          id
          health_system {
            id
            label
          }
          msp {
            id
            label
          }
          vms_type {
            id
            label
          }
        }
      }
      invoices {
        id
      }
      company_id
      company {
        id
      }
      facility_cancellation_hours
      ehr_system_id
      mileage_requirement
      variable_shift_times
      min_years_of_experience
      jobs_detail_raw
      vms_auto_update
    }
  }
`;

export default AssignmentsShow;
