import React from 'react';
import { ENUMS } from '../Enum/enums';
import AdministratorInput from './AdministratorInput';

const ClinicalAccountManagerInput = (props) => <AdministratorInput {...props} />;

ClinicalAccountManagerInput.defaultProps = {
  label: 'Clinical Account Manager',
  source: 'search.administrator_id',
  filter: { permission_group_roles: [ENUMS.PermissionGroup.role.sales_recruiter], status: 'active' },
  sort: { field: 'id', order: 'ASC' },
  perPage: 100,
};

export default ClinicalAccountManagerInput;
