import React from 'react';
import { gql } from '@apollo/client';
import {
  FunctionField,
  SelectInput,
  BooleanInput,
  Datagrid,
  TextField,
  TextInput,
} from 'react-admin';
import IdInput from 'components/Filter/IdInput';
import List from 'components/List';
import {
  AccountManagerFilter,
  MarketFilter,
  StateFilter,
  AdministratorFilter,
  SearchFilter,
} from 'components/Filter';
import ReferenceInput from 'components/ReferenceInput';
import { ENUMS } from 'components/Enum/enums';
import IdField from 'components/IdField';
import { LocationListActions } from './ListActions';
import VmsTypeInput from 'components/VmsTypeInput';
import HealthSystemInput from 'components/HealthSystemInput';
import MspInput from 'components/MspInput';
import { Link } from 'react-router-dom';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import AgencyInput from 'components/AgencyInput';

const LocationsFilters = [
  <SearchFilter />,
  <IdInput />,
  <AccountManagerFilter alwaysOn source="search.account_manager_id" label="Account Manager" />,
  <AdministratorFilter
    alwaysOn
    source="search.sales_associate_id"
    label="Sales Associate"
    filter={{ permission_group_roles: [ENUMS.PermissionGroup.role.sales_associate] }}
  />,
  <TextInput label="Phone Search" source="search.phone_number" alwaysOn />,
  <TextInput label="Address Search" source="search.address_first" alwaysOn />,
  <MarketFilter alwaysOn />,
  <StateFilter alwaysOn />,
  <ReferenceInput
    alwaysOn
    emptyText="All"
    label="Location Type"
    source="search.location_type_id"
    reference="LocationType"
  >
    <SelectInput optionText="label" label="Location Type" />
  </ReferenceInput>,
  <BooleanInput alwaysOn source="search.onboarding_complete" label="Onboard Complete" />,
  <BooleanInput alwaysOn source="search.verified" label="Verified" />,
  <SelectInput
    choices={[
      { id: 1, name: '0-69%' },
      { id: 2, name: '70-79%' },
      { id: 3, name: '80-89%' },
      { id: 4, name: '90-100%' },
    ]}
    alwaysOn
    source="search.fill_ratio"
    label="Fill Ratio"
  />,
  <VmsTypeInput />,
  <HealthSystemInput />,
  <MspInput />,
  <BooleanInput alwaysOn source="search.no_vms" label="Not A VMS" />,
  <BooleanInput alwaysOn source="search.has_balance" label="Has Balance" />,
  <TextInput label="Referral" source="referral" />,
  <TextInput label="UTM Source" source="search.utm_source" />,
  <TextInput label="UTM Campaign" source="search.utm_campaign" />,
  <EnumSelectInput alwaysOn source="search.plan" label="Plan" />,
  <BooleanInput source="search.has_agencies" label="Has Agencies" />,
  <BooleanInput source="search.has_approved_agencies" label="Has Approved Agencies" />,
  <AgencyInput source="search.agency_id" />,
];

const LocationsList = () => (
  <List
    actions={<LocationListActions />}
    exporter={false}
    filters={LocationsFilters}
    data-testid="locations-list"
    bulkActionButtons={false}
  >
    <Datagrid bulkActionButtons={false}>
      <IdField label="ID" sortBy="id" />
      <TextField label="Name" source="name" />
      <TextField label="Unit" source="unit" />
      <TextField label="Status" source="status" />
      <TextField label="Type" source="location_type.label" />
      <TextField label="Office Phone" source="phone_number" />
      <TextField label="No. Jobs" source="job_count" />
      <TextField label="Market" source="market.display_name" />
      <FunctionField
        label="Account Manager"
        render={(r) =>
          r.account_manager &&
          r.account_manager.account && (
            <Link to={`/Administrator/${r.account_manager_id}/show`}>
              {r.account_manager.account.name}
            </Link>
          )
        }
      />
      <FunctionField
        label="Sales Associate"
        render={(r) =>
          r.sales_associate &&
          r.sales_associate.account && (
            <Link to={`/Administrator/${r.sales_associate_id}/show`}>
              {r.sales_associate.account.name}
            </Link>
          )
        }
      />
      <TextField label="VMS Type" source="company.vms_type.label" />
      <TextField label="MSP" source="company.msp.label" />
      <TextField label="Health System" source="company.health_system.label" />
    </Datagrid>
  </List>
);

LocationsList.query = gql`
  query GET_LIST($input: LocationQueryInput) {
    _locationsMeta(input: $input) {
      count
    }
    locations(input: $input) {
      id
      name
      phone_number
      status
      location_type {
        id
        label
      }
      market {
        id
        display_name
      }
      account_manager_id
      account_manager {
        id
        account {
          id
          first_name
          last_name
          name
        }
      }
      billing_groups {
        id
      }
      sales_associate_id
      sales_associate {
        id
        account {
          id
          first_name
          last_name
          name
        }
      }
      company {
        id
        name
        vms_type {
          id
          label
        }
        msp {
          id
          label
        }
        health_system {
          id
          label
        }
      }
      state {
        id
        name
      }
      job_count
      unit
    }
  }
`;

export default LocationsList;
