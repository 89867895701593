import React from 'react';
import { Box, Card, CardHeader, CardContent, List, ListItem, ListItemText } from '@mui/material';
import { DisplayHelpers } from 'utils/helpers';
import config from 'config';
import { useRecordContext } from 'ra-core';

const LocationAside = () => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }

  return (
    <Box ml={2} width={320} flexShrink={0}>
      <Card>
        <CardHeader title={record.name} subheader={`ID ${record.id}`} />
        <CardContent>
          <List>
            <ListItem>
              <ListItemText
                primary={
                  record.account_manager ? DisplayHelpers.name(record.account_manager.account) : '-'
                }
                secondary="Account Manager"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  record.clinical_account_manager
                    ? DisplayHelpers.name(record.clinical_account_manager.account)
                    : '-'
                }
                secondary="Clinical Account Manager"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  record.sales_associate ? DisplayHelpers.name(record.sales_associate.account) : '-'
                }
                secondary="Sales Associate"
              />
            </ListItem>
            {record.payment_gateway && (
              <ListItem
                button
                component="a"
                target="_blank"
                href={`${config.rails_url}/hosted-billing/${record.billing_id}`}
              >
                <ListItemText
                  primaryTypographyProps={{ color: 'primary' }}
                  primary="Billing gateway"
                />
              </ListItem>
            )}
          </List>
        </CardContent>
      </Card>
    </Box>
  );
};

export default LocationAside;
