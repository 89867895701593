import React from 'react';
import { TextField, Datagrid, SimpleShowLayout, Pagination, ReferenceManyField } from 'react-admin';
import LinkField from 'components/LinkField';

const LocationsTab = () => (
  <SimpleShowLayout>
    <ReferenceManyField
      label=""
      pagination={<Pagination />}
      reference="Location"
      target="agency_id"
    >
      <Datagrid bulkActionButtons={false}>
        <LinkField label="id" source="id" />
        <TextField label="Status" source="status" />
        <LinkField label="Company" source="company.name" sortBy="company_name" />
        <LinkField label="Name" source="name" />
        <TextField label="Market" source="market.display_name" sortBy="market_name" />
        <TextField label="State" source="state.name" sortBy="state_name" />
      </Datagrid>
    </ReferenceManyField>
  </SimpleShowLayout>
);

export default React.memo(LocationsTab);
