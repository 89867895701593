import React, { ApolloClient, ApolloProvider, from, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import config from 'config';
import { createContext, useContext } from 'react';
import { useTokenStorage } from 'hooks/useTokenStorage';

type ApolloClientContext = {
  client: ApolloClient;
};

const apolloClientContext = createContext<ApolloClientContext>({
  client: null,
});

export const ApolloClientContextProvider = ({ children }) => {
  const { getToken } = useTokenStorage();

  const client = new ApolloClient({
    uri: `${config.graphql_url}/graphql`,
    cache: new InMemoryCache(),
    link: from([
      setContext(async () => {
        const storedToken = await getToken();
        return {
          headers: {
            authorization: storedToken ? `Bearer ${storedToken}` : undefined,
          },
        };
      }),
      new RetryLink(),
      new HttpLink({
        uri: `${config.graphql_url}/graphql`,
        fetchOptions: {
          mode: 'cors',
          signal: new AbortController().signal,
        },
      }),
    ]),
  });

  return (
    <apolloClientContext.Provider value={{ client }}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </apolloClientContext.Provider>
  );
};

export const useApolloClientContext = () => useContext(apolloClientContext);
